<template>
  <div class="filter-content">
    <!-- 顶部标题 -->
    <div class="menu-title d-flex justify-space-between px-4 py-2">
      <p>
        <v-icon icon="mdi-filter-menu-outline" class="mr-4"></v-icon>
        <span>Filters</span>
      </p>
      <v-icon icon="mdi-close" class="ms-4" @click="coloseFn"></v-icon>
    </div>
    <div class="filter-content pa-4">
      <category-search ref="categorySearchRef" :show-reset="false" />
    </div>

    <!-- 按钮 -->

    <v-row class="pb-4 px-4 filters-btn-wrapper w-100 ma-0">
      <v-col cols="6">
        <v-btn class="w-100 mt-3" variant="outlined" @click="resetFn">Reset</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn class="w-100 mt-3" color="primary" @click="applyFiltersFn">Apply Filters</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import categorySearch from '~/pages/category/category-search'
const categorySearchRef = ref(null)
const emit = defineEmits(['coloseFn', 'searchQuery'])
// 重置
const resetFn = () => {
  if (categorySearchRef.value) categorySearchRef.value.reset()
}
// 搜索
const applyFiltersFn = () => {
  if (categorySearchRef.value) categorySearchRef.value.search()
  coloseFn()
}
const coloseFn = () => {
  emit('coloseFn')
}
</script>

<style lang="scss" scoped>
.tips {
  font-size: 13px;
  background: #ffffc0;
  color: #f44336;
  padding: 2px 5px;
  margin: 10px 0;
}
.menu-title {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9999;
}
.w-100.v-input {
  width: 100%;
  max-width: none;
}
.filter-content {
  padding-bottom: 100px;
}
.filters-btn-wrapper {
  position: fixed;
  bottom: 0;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
</style>
